import React from 'react';

import './Header.scss';

function Header({ menu }) {
    return <header className={'header'}>
        <div onClick={menu.open} className={'menu-trigger container left'}>
            <i className={'icon icon-menu'}/>
            <span>Menu</span>
        </div>
        <span className={'header-item right'}>Logo</span>
    </header>
}

export default Header;