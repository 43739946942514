import React, {useState, useEffect, useContext} from 'react';
import { useAsync } from "react-async";
import { checkCadnums, createReestr } from './../ajax/api-requests';
import { Redirect } from "react-router-dom";
import { UserContext } from './../context/Context';
import { usePreloader } from './../component/Preloader';
import {useSelectiveCheckboxes} from "./../hook/UseSelectiveCheckboxes";

import './MakeReestr.scss';

function MakeReestrPage() {
    const { data:user } = useContext(UserContext);
    const { data:checkingResponse, run:asyncCheckCadnums } = useAsync({ deferFn: checkCadnums });
    const { data:submitResponse, run:asyncCreateReestr } = useAsync({ deferFn: createReestr });
    const [ Preloader, openPreloader, closePreloader ] = usePreloader('preloader');

    const [ rawCadnums, setRawCadnums ] = useState("");
    const [ selectedObjects, handleCheck, selectAll, handleCheckAll, rebuildSelectedObjects ] = useSelectiveCheckboxes([], 'id');
    const [ title, setTitle ] = useState("");
    const [ type, setType ] = useState(1);
    const [ email, setEmail ] = useState('');

    useEffect(() => {
        setEmail(user.email);
        document.title = 'Заказать реестр'
    }, []);

    useEffect(() => {
        if (submitResponse) {
            closePreloader();
        }
    }, [ submitResponse ]);

    useEffect(() => {
        if (checkingResponse) {
            rebuildSelectedObjects(checkingResponse.data.response.objects, 'id', true);
            closePreloader();
        }
    }, [ checkingResponse ]);

    const handleCheckCadnums = (e) => {
        e.preventDefault();

        if (!rawCadnums.trim()) {
            return;
        }

        openPreloader();
        asyncCheckCadnums(rawCadnums);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        openPreloader();
        let objects = [];
        for (let objectId in selectedObjects) {
            if (selectedObjects[objectId]) {
                objects.push(objectId);
            }
        }
        asyncCreateReestr(title, type, email, objects);
    };

    if (submitResponse && submitResponse.data.status) {
        return <Redirect to={{ pathname: "/reestr" }}/>;
    }

    return <div className={'container'}>
        <Preloader />
        <form action={'#'} onSubmit={handleSubmit}>
            <div className={'input-box'}>
                <label>
                    <span>Название реестра</span>
                    <input className={'input-default'} type={'text'} value={title} onChange={(e) => setTitle(e.target.value)}/>
                </label>
            </div>
            <div className={'input-box'}>
                <label>
                    <span>Тип выписок</span>
                    <select className={'input-default'} value={type} onChange={(e) => setType(e.target.value)}>
                        <option value={1}>Простая</option>
                        <option value={2}>Переход прав</option>
                    </select>
                </label>
            </div>
            <div className={'input-box'}>
                <label>
                    <span>Email для оповещений</span>
                    <input className={'input-default'} type={'text'} value={email} onChange={(e) => setEmail(e.target.value)}/>
                </label>
            </div>
            <div className={'input-box'}>
                <label>
                    <span>Кадастровые номера</span>
                    <textarea
                        className={'input-default'}
                        placeholder={'Кадастровые номера'}
                        onChange={(e) => setRawCadnums(e.target.value)}
                        onBlur={handleCheckCadnums}
                    />
                </label>
            </div>
            {checkingResponse && checkingResponse.data.status
                ? <>
                    <div className={'row'}>
                        <div className={'col-4'}>Запрошено {checkingResponse.data.response['cadnums_count']}</div>
                        <div className={'col-4'}>Найдено {checkingResponse.data.response['objects_count']}</div>
                        {checkingResponse.data.response.objects.length > 0
                            ? <div className={'col-4'}><input className={'btn btn-fluid bg-dark-gray'} type={'submit'} value={'Заказать реестр'}/></div>
                            : null
                        }
                    </div>
                    <div className={'table-wrapper'}>
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    <th className={'check-all-th content-middle'}><input type='checkbox' value={'all'} checked={selectAll} onChange={handleCheckAll}/></th>
                                    <th className={'cadnum-th'}>Кадастровый номер</th>
                                    <th className={'address-th v-big'}>Адрес</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                checkingResponse.data.response.objects.map((object) => {
                                    return <tr key={object.id}>
                                        <td className={'content-middle'}><input type='checkbox' value={object.id} checked={selectedObjects[object.id]} onChange={handleCheck}/></td>
                                        <td>{object.cadnum}</td>
                                        <td className={'v-big'}>{object.address || 'Адрес не определен'}</td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    </>
                : null
            }
        </form>
    </div>
}

export default MakeReestrPage;