import React, {useState, useContext, useEffect} from 'react';
import { createPortal } from 'react-dom';
import { NavLink, useHistory, useParams } from "react-router-dom";
import { PreloaderDataContext } from './../context/Context';
import { useAsync } from "react-async";

import './Preloader.scss';

export function PreloaderLink({ url, preloaders, children, loadCache, onClick }) {
    const history = useHistory();
    const [ Preloader, openPreloader, closePreloader ] = usePreloader('preloader');
    const { preload, setPreload } = useContext(PreloaderDataContext);
    const { data, run } = useAsync({ deferFn: preloadData });

    useEffect(() => {
        if (data) {
            closePreloader();
            setPreload({
                [url]: data
            });
            history.push(url);
        }
    }, [ data ]);

    const handleLoading = (e) => {
        e.preventDefault();
        onClick();
        if (loadCache && preload[url]) {
            return history.push(url);
        }

        openPreloader();
        run(preloaders);
    };

    return <>
        <Preloader />
        <NavLink
            onClick={handleLoading}
            to={url}
            activeClassName={'active'}
        >{children}</NavLink>
    </>;
}

PreloaderLink.defaultProps = {
    loadCache: false,
    onClick: () => {}
};

export const PreloaderComponent = ({ children, preloaders }) => {
    const params = useParams();
    const history = useHistory();
    const [ Preloader, openPreloader, closePreloader ] = usePreloader('preloader');
    const { preload } = useContext(PreloaderDataContext);
    const { data, run } = useAsync({ deferFn: preloadData });

    const elements = React.Children.toArray(children);
    const processedPreloaders = preloaders.map(preloader => {
        if (preloader.arguments) {
            return preloader;
        } else {
            preloader.arguments = [];
        }

        for (let prop in params) {
            if (params.hasOwnProperty(prop))
                preloader.arguments.push(params[prop]);
        }

        return preloader;
    });

    useEffect(() => {
        if (!preload[history.location.pathname]) {
            openPreloader();
            run(processedPreloaders);
        } else {
            closePreloader();
        }
    },[]);

    if (preload[history.location.pathname]) {
        return elements.map(element => React.cloneElement(element, { ...preload[history.location.pathname] }));
    }

    if (!data) {
        return <Preloader />;
    }

    return elements.map(element => React.cloneElement(element, { ...data }));
};

const preloadData = function ([ preloaders ]) {
    return Promise.all(preloaders.map(preloader => {
        return preloader.callback(preloader.arguments || []);
    })).then((responses) => {
        return responses.reduce((acc, response, i) => {
            acc[preloaders[i]['name']] = response.data.response;
            return acc;
        }, {});
    });
};

const PreloaderAnimation = ({ isOpen = false, elementId = 'root' }) => {
    if (isOpen === false) {
        return null;
    }

    return createPortal(
        <div className={'preloader-wrapper'}>
            <div className="lds-ellipsis">
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>,
        document.getElementById(elementId)
    );
};

export const usePreloader = (elementId = 'root') => {
    const [isOpen, setOpen] = useState(false);

    const open = () => {
        setOpen(true);
    };

    const close = () => {
        setOpen(false);
    };

    const PreloaderAnimationWrapper = () => {
        return <PreloaderAnimation
            isOpen={isOpen}
            elementId={elementId}
        />
    };

    return [ PreloaderAnimationWrapper, open, close ];
};