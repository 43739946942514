import React, { useEffect, useContext } from 'react';

import './LeftBar.scss';
import DefaultAvatar from './../img/avatar.png'
import { NavLink } from "react-router-dom";
import { getOrganizations, getReestrList } from "../ajax/api-requests";
import { PreloaderLink } from "../component/Preloader";
import { UserContext } from './../context/Context';

function LeftBar({ menu }) {
    const { data:user, update } = useContext(UserContext);

    useEffect(() => {
        if (menu.close) {
            const links = document.querySelectorAll('#nav-block a');

            for (let i=0; i < links.length; i++) {
                links[i].addEventListener('onclick', () => {
                    console.log('close');
                    menu.close();
                });
            }
        }
    }, [ menu ]);

    const logout = (event) => {
        event.preventDefault();
        localStorage.setItem('id', null);
        localStorage.setItem('email', null);
        localStorage.setItem('firstName', null);
        localStorage.setItem('lastName', null);
        localStorage.setItem('token', null);

        update({
            ...user,
            id: null,
            firstName: null,
            lastName: null,
            email: null,
            token: null,
        });
    };

    return <>
        <nav className={'left-bar animated-left'} id={'left-bar'}>
            <br />
            <div className={'container row'}>
                <div className={'col-4'}>
                    <img className={'avatar'} src={DefaultAvatar}/>
                </div>
                <div className={'col-8'}>
                    <p className={'clear-fix'}>Добро пожаловать, <strong>{user.firstName} {user.lastName}</strong></p>
                    <p className={'clear-fix'}>
                        <a className={'bar-item'} href={'#'} onClick={logout}>
                            <i className={'icon icon-logout'}/>
                        </a>
                    </p>
                </div>
            </div>
            <hr />
            <div className={'container'}>
                <h3>Dashboard</h3>
            </div>
            <div className={'nav-block'} id={'nav-block'}>
                <NavLink onClick={menu.close} to={'/make_reestr'} activeClassName={'active'}>Заказать реестр</NavLink>
                <PreloaderLink onClick={menu.close} url={'/reestr'} loadCache={true} preloaders={[{ callback: getReestrList, name: 'reestrs' }]}>
                    Список реестров
                </PreloaderLink>
                <PreloaderLink onClick={menu.close} url={'/organization'} loadCache={true} preloaders={[{ callback: getOrganizations, name: 'organizations' }]}>
                    Организации
                </PreloaderLink>
            </div>
        </nav>
        <div onClick={menu.close ? menu.close : () => {}} className={'overlay animated-opacity'} id={'overlay'}/>
    </>;
}

export default LeftBar;