import axios from "./../service/axios";

const saveFileFromResponse = (response, openInNewTab = false) => {
    let disposition = response.headers['content-disposition']
    let filename;
    if (disposition) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        var matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
        }
    }

    // response.data is an empty object
    const blob = new Blob([response.data], {
        type: response.headers['x-mime-type']
    });

    let downloadUrl = URL.createObjectURL(blob);

    if (openInNewTab) {
        window.open(downloadUrl, '_blank')
    } else {
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
    }
};

export const login = ([ email, password ]) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    return axios({
        method: 'post',
        url: '/login',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
    });
};

export const checkCadnums = ([ cadnums ]) => {
    return axios({
        method: 'post',
        url: '/api/super/check_cadnum',
        data: {
            cadnums
        },
    });
};

export const createReestr = ([ title, type, email, objects ]) => {
    return axios({
        method: 'post',
        url: '/api/super/reestr',
        data: {
            title,
            type,
            email,
            objects
        },
    });
};

export const remakeRequests = ([ reestrId, requests ]) => {
    return axios({
        method: 'post',
        url: '/api/super/reestr/' + reestrId + '/reorder',
        data: {
            requests
        },
    });
};

export const getReestrList = () => {
    return axios({
        method: 'get',
        url: '/api/super/reestr'
    });
};

export const getReestr = ([ id ]) => {
    return axios({
        method: 'get',
        url: '/api/super/reestr/' + id
    });
};

export const downloadExtraction = ([ id, type ]) => {
    return axios.get('/api/extraction/' + id + '/' + type, {
        responseType: 'blob'
    }).then(response => {
        return type === 'html'
            ? saveFileFromResponse(response, true)
            : saveFileFromResponse(response);
    });
};

export const downloadReestr = ([ id ]) => {
    return axios.get('/api/super/reestr/' + id + '/download', {
        responseType: 'blob'
    }).then(response => {
        return saveFileFromResponse(response);
    });
};

export const buildReestr = ([ id ]) => {
    return axios.post('/api/super/reestr/' + id + '/build');
};

export const getOrganizations = () => {
    return axios.get('/api/super/organization');
};

export const getOrganization = ([ id ]) => {
    return axios({
        method: 'get',
        url: '/api/super/organization/' + id
    });
};

export const updateOrganization = ([ id, data ]) => {
    return axios({
        method: 'post',
        url: '/api/super/organization/' + id,
        data
    });
};