export default {
    getItem: function(key, defaultValue = null) {
        if (!localStorage.hasOwnProperty(key)) {
            return defaultValue;
        }

        return JSON.parse(localStorage.getItem(key));
    },
    setItem: function(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    clear: function() {
        localStorage.clear();
    }
}