import React, { useContext, useState } from 'react';
import { User, UserContext, PreloaderDataContext, PreloaderContext } from './../context/Context';
import App from './../App';
import axios from './../service/axios';
import { usePreloader } from "../component/Preloader";

const AppWithContext = () => {
    const user = useContext(UserContext);
    return <App user={user}/>;
};

function ConnectedApp() {
    const [user, setUser] = useState(User);
    const [preload, setPreload] = useState({});
    const [ Preloader, openPreloader, closePreloader ] = usePreloader('preloader');

    if (user.token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
    }

    const setPreloadData = (data) => {
        setPreload({
            ...preload,
            ...data
        });
    };

    return <UserContext.Provider value={{ data: user, update: setUser }}>
        <PreloaderDataContext.Provider value={{ preload, setPreload: setPreloadData }}>
            <PreloaderContext.Provider value={{ openPreloader, closePreloader }}>
                <AppWithContext />
                <Preloader />
            </PreloaderContext.Provider>
        </PreloaderDataContext.Provider>
    </UserContext.Provider>;
}

export default ConnectedApp;