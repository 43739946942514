import React from 'react';
import LocalStorage from './../service/localStorage';

export const User = {
    id: LocalStorage.getItem('id'),
    email: LocalStorage.getItem('email'),
    firstName: LocalStorage.getItem('firstName'),
    lastName: LocalStorage.getItem('lastName'),
    token: LocalStorage.getItem('token'),
};

export const UserContext = React.createContext({
    user: User,
    changeUser: () => {},
    logout: () => {}
});

export const PreloaderDataContext = React.createContext({});

export const PreloaderContext = React.createContext({});