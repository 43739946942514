import React, { useEffect } from 'react';
import { getOrganization } from './../ajax/api-requests';
import { PreloaderLink } from './../component/Preloader';

function OrganizationsListPage({ organizations }) {
    useEffect(() => {
        document.title = 'Список организаций';
    }, []);

    return <div className={'container'}>
        <h3>Организации</h3>
        <table className={'table'}>
            <thead>
                <tr>
                    <th>Название</th>
                    <th>Баланс</th>
                </tr>
            </thead>
            <tbody>
            {
                organizations.map((organization) => {
                    return <tr key={organization['id']}>
                        <td><PreloaderLink
                            url={'/organization/' + organization['id']}
                            preloaders={[{ callback: getOrganization, arguments: [ organization['id'] ], name: 'organization'}]}
                        >{organization['name']}</PreloaderLink></td>
                        <td>{organization['balance']} руб.</td>
                    </tr>
                })
            }
            </tbody>
        </table>
    </div>
}

export default OrganizationsListPage;