import React, { useState, useContext, useEffect } from 'react';

function getCheckboxes(items, keyValue, checked = false) {
    return items.reduce((acc, value) => {
        acc[value[keyValue]] = checked;
        return acc;
    }, {})
}

export const useSelectiveCheckboxes = (items, keyValue, checked = false) => {
    const [ selectiveItems, setSelectiveItems ] = useState(getCheckboxes(items, keyValue, checked));
    const [ selectAll, setSelectAll ] = useState(checked);

    const rebuildSelectiveItems = (items, keyValue, checked = false) => {
        setSelectiveItems({
            ...getCheckboxes(items, keyValue, checked)
        });

        setSelectAll(checked);
    };

    const handleSelectAll = (event) => {
        const checkboxes = {};

        for (let prop in selectiveItems) {
            checkboxes[prop] = event.target.checked;
        }

        setSelectAll(event.target.checked);
        setSelectiveItems({
            ...checkboxes
        });
    };

    const handleCheck = (event) => {
        if (!event.target.checked) {
            setSelectAll(false);
        }

        setSelectiveItems({
            ...selectiveItems,
            [event.target.value]: event.target.checked
        });
    };

    return [ selectiveItems, handleCheck, selectAll, handleSelectAll, rebuildSelectiveItems ]
};