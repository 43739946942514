import React, { useState, useEffect } from 'react';
import { useAsync } from "react-async";
import { login } from './../ajax/api-requests';
import { usePreloader } from './Preloader';

import './LoginFormComponent.scss';

function LoginFormComponent({authenticate}) {
    const { isPending, data:response, run } = useAsync({ deferFn: login });
    const [ Preloader, openPreloader, closePreloader ] = usePreloader('preloader');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (isPending) {
            openPreloader();
        }

        if (response && response.data.status) {
            closePreloader();
            const user = response.data.response.user;
            return authenticate({
                id: user.id,
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                token: user.token
            });
        }
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        if (email && password) {
            run(email, password);
        }
    };

    return <form className='login-form' onSubmit={handleSubmit}>
        <h1>Вход</h1>
        <div className='input-box'>
            <input className={'input-default'} type='text' onChange={(e) => setEmail(e.target.value)} placeholder={'Email'}/>
        </div>
        <div className='input-box'>
            <input className={'input-default'} type='password' onChange={(e) => setPassword(e.target.value)} placeholder={'Password'}/>
        </div>
        <div className={'input-box'}>
            <input type='submit' className={'btn input-default bg-dark-gray'} value='login'/>
        </div>
        <Preloader />
    </form>
}

export default LoginFormComponent;