import React, { useEffect, useState, useContext } from 'react';
import { useAsync } from "react-async";
import {
    downloadExtraction,
    downloadReestr,
    buildReestr,
    remakeRequests
} from './../ajax/api-requests';
import { PreloaderContext } from './../context/Context'
import { useSelectiveCheckboxes } from './../hook/UseSelectiveCheckboxes';
import './ReestrPage.scss';

function ReestrPage({ reestr:data }) {
    const [ reestr, setReestr ] = useState(data);
    const { run:runDownloadExtraction } = useAsync({ deferFn: downloadExtraction });
    const { run:runDownloadReestr } = useAsync({ deferFn: downloadReestr });
    const { run:runBuildReestr } = useAsync({ deferFn: buildReestr });
    const { data:responseRemakeRequests, run:runRemakeRequests } = useAsync({ deferFn: remakeRequests });
    const [ selected, handleSelect, selectAll, handleSelectAll ] = useSelectiveCheckboxes(data.requests, 'id');
    const { openPreloader, closePreloader } = useContext(PreloaderContext);

    useEffect(() => {
        document.title = 'Данные о реестре';
    }, []);

    useEffect(() => {
        if (responseRemakeRequests) {
            closePreloader();
        }
    }, [ responseRemakeRequests ]);

    const handleDownloadExtraction = (id, type, event) => {
        event.preventDefault();
        runDownloadExtraction(id, type);
    };

    const handleDownloadReestr = (event) => {
        event.preventDefault();
        runDownloadReestr(reestr.id);
    };

    const handleRebuildReestr = (event) => {
        event.preventDefault();
        setReestr({
            ...reestr,
            download_status: 2,
            rebuild: false
        });
        runBuildReestr(reestr.id);
    };

    const handleRemakeRequests = (event) => {
        event.preventDefault();
        openPreloader();
        let requests = [];
        for (let requestId in selected) {
            if (selected[requestId]) {
                requests.push(requestId);
            }
        }

        runRemakeRequests(reestr.id, requests);
    };

    const reestrBuild = reestr['download_status'] === 1
        ? 'Не готов'
        : reestr['download_status'] === 2
            ? 'Реестр собирается... Обновите страницу через пару секунд.'
            : <a href={'#'} onClick={handleDownloadReestr}>Скачать реестр ({reestr['requests_built']}/{reestr.requests.length})</a>
    ;

    let hasSelected = false;
    for (let prop in selected) {
        if (selected[prop]) {
            hasSelected = true;
            break;
        }
    }

    return <>
        <div className={'container'}>
            <h3>Реестр</h3>
            <div className={'row'}>
                <div className={'col-5'}>Название:</div>
                <div className={'col-7'}>{reestr.title}</div>
            </div>
            <div className={'row'}>
                <div className={'col-5'}>Тип выписок:</div>
                <div className={'col-7'}>{reestr.type === 1 ? 'Простая' : 'Переход прав'}</div>
            </div>
            <div className={'row'}>
                <div className={'col-5'}>Дата заказа:</div>
                <div className={'col-7'}>{reestr.ctime}</div>
            </div>
            <div className={'row'}>
                <div className={'col-5'}>Пользователь:</div>
                <div className={'col-7'}>{reestr.user.email}</div>
            </div>
            <div className={'row'}>
                <div className={'col-5'}>Реестр:</div>
                <div className={'col-7'}>{reestrBuild}</div>
            </div>
            {
                reestr['download_status'] !== 2
                    ? <div className={'row'}>
                        <div className={'col-5'}>Собрать:</div>
                        <div className={'col-7'}><a href={'#'} onClick={handleRebuildReestr}>click</a></div>
                    </div>
                    : ""
            }
        </div>
        <br />
        <div className={'container'}>
            <h3>Список объектов</h3>
            <table className={'requests-list table'}>
                <thead>
                    <tr>
                        <th><input type='checkbox' value={'all'} checked={selectAll} onChange={handleSelectAll}/></th>
                        <th>Кадастровый номер</th>
                        <th className={'v-big'}>Адрес</th>
                        <th>Статус</th>
                        <th className={'v-big'}>Дата заказа</th>
                    </tr>
                </thead>
                <tbody>
                {
                    reestr.requests.map((request) => {
                        return <tr key={request.id}>
                            <td><input type='checkbox' value={request.id} checked={selected.hasOwnProperty(request.id) && selected[request.id]} onChange={handleSelect}/></td>
                            <td>{request.object.cadnum}</td>
                            <td className={'v-big'}>{request.object.address || 'Адрес не определен'}</td>
                            <td>{
                                request.status === 'done'
                                    ? <ul className={'horizontal-list'}>
                                        <li><a href={'#'} onClick={handleDownloadExtraction.bind(null, request.extraction.id, 'zip')}>ZIP</a></li>
                                        <li><a href={'#'} onClick={handleDownloadExtraction.bind(null, request.extraction.id, 'html')}>HTML</a></li>
                                        <li><a href={'#'} onClick={handleDownloadExtraction.bind(null, request.extraction.id, 'pdf')}>PDF</a></li>
                                    </ul>
                                    : request.status
                            }</td>
                            <td className={'v-big'}>{request.ctime}</td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
        {
            hasSelected
                ? <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-3 col-s-6'}>С отмеченными: </div>
                        <div className={'col-3 col-s-6'}><button className={'btn btn-fluid bg-dark-gray'} onClick={handleRemakeRequests}>Перезаказать</button></div>
                    </div>
                </div>
                : null
        }
    </>;
}

export default ReestrPage;