import React, {useEffect, useState} from 'react';
import { useAsync } from "react-async";
import { updateOrganization } from './../ajax/api-requests';
import './OrganizationPage.scss';
import {usePreloader} from "../component/Preloader";

const normalizeNumericString = (stringValue) => {
    return stringValue.replace(',', '.');
};

const getNumericValue = (stringValue) => {
    if (stringValue === '') {
        return 0;
    }

    const value = normalizeNumericString(stringValue);
    const parsed = parseFloat(value);

    if (isNaN(parsed)) {
        return 0;
    }

    return parsed;
};

const getOnlyContractModifiedValues = (contract) => {
    const modifiedContract = {};

    for (let prop in contract) {
        if (!contract.hasOwnProperty(prop)) {
            continue;
        }

        if (prop === 'id' || prop.indexOf('_count') !== -1) {
            continue;
        }

        modifiedContract[prop] = contract[prop];
    }

    return modifiedContract;
};

function OrganizationPage({ organization }) {
    const { data:response, run } = useAsync({ deferFn: updateOrganization });
    const [ Preloader, openPreloader, closePreloader ] = usePreloader('preloader');

    const [contract, setContract] = useState(getOnlyContractModifiedValues(organization['contract']));
    const [orgInfo, setOrgInfo] = useState({
        name: organization.name,
        balance: organization.balance,
        balanceDiff: 0
    });
    const [monitoringPrice, setMonitoringPrice] = useState(organization['contract']['monitoring_price']);

    useEffect(() => {
        document.title = 'Страница организации';
    }, []);

    useEffect(() => {
        if (response) {
            closePreloader();
        }
    }, [ response ]);

    const tbl = {
        poll: { rowName: 'Опрос' },
        search: { rowName: 'Поисковые запросы' },
        extraction: { rowName: 'Выписки' },
        monitoring: { rowName: 'Мониторинг' }
    };

    const handleMonitoringPrice = (e) => {
        const pricePerMonth = normalizeNumericString(e.target.value);
        setContract({...contract, 'monitoring_price': getNumericValue(pricePerMonth)});
        setMonitoringPrice(pricePerMonth);
    };

    const handleUpdate = (type, e) => {
        e.preventDefault();
        openPreloader();

        const balanceDiff = parseInt(orgInfo.balanceDiff);

        if (type === 'general') {
            run(organization.id, {
                name: orgInfo.name,
                balance_diff: balanceDiff
            });

            setOrgInfo({
                ...orgInfo,
                balance: parseInt(orgInfo.balance) + balanceDiff,
                balanceDiff: 0
            });
        } else {
            console.log(contract);
            run(organization.id, contract);
        }
    };

    return <div>
        <Preloader/>
        <div className={'container'}>
            <h3>Организация</h3>
            <div className={'row'}>
                <div className={'col-3 col-s-6'}>Название:</div>
                <div className={'col-3 col-s-6'}><input type={'text'} value={orgInfo['name']} onChange={(e) => setOrgInfo({...orgInfo, name: e.target.value})}/></div>
            </div>
            <div className={'row'}>
                <div className={'col-3 col-s-6'}>Баланс:</div>
                <div className={'col-3 col-s-6'}><input type={'text'} value={orgInfo['balance']} readOnly={true} disabled={true}/></div>
            </div>
            <div className={'row'}>
                <div className={'col-3 col-s-6'}>Изменить на:</div>
                <div className={'col-3 col-s-6'}><input type={'text'} value={orgInfo.balanceDiff} onChange={(e) => setOrgInfo({...orgInfo, balanceDiff: e.target.value})}/></div>
            </div>
            <div className={'row'}>
                <div className={'col-2 col-s-6'}>
                    <button className={'btn btn-fluid bg-dark-gray'} onClick={handleUpdate.bind(null, 'general')}>Сохранить</button>
                </div>
            </div>
        </div>
        <br />
        <br />
        <div className={'container'}>
            <h3>Тарифный план</h3>
            <table className={'table'}>
                <thead>
                    <tr>
                        <th/>
                        <th colSpan={2}>Стоимость</th>
                        <th>Лимит</th>
                        <th>Количество</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ['poll', 'search', 'extraction', 'monitoring'].map(function(type, i) {
                            return <tr key={i}>
                                <td>{tbl[type]['rowName']}</td>
                                {type !== 'monitoring'
                                    ? <td colSpan={2}><input type={'text'} value={contract[type + '_price']} onChange={(e) => setContract({...contract, [type + '_price']: getNumericValue(e.target.value)})} /></td>
                                    : <>
                                        <td>
                                            <ul className={'list-default'}>
                                                <li>Месяц</li>
                                                <li>Год</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul className={'list-default'}>
                                                <li><input type={'text'} value={monitoringPrice} onChange={handleMonitoringPrice} /></li>
                                                <li><input type={'text'} value={contract[type + '_price'] * 12} readOnly={true} disabled={true} /></li>
                                            </ul>
                                        </td>
                                    </>
                                }
                                <td><input type={'text'} value={contract[type + '_limit']} onChange={(e) => setContract({...contract, [type + '_limit']: getNumericValue(e.target.value)})} /></td>
                                <td>{organization['contract'][type + '_count']}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
            <div className={'row'}>
                <div className={'col-2 col-s-6'}>
                    <button className={'btn btn-fluid bg-dark-gray'} onClick={handleUpdate.bind(null, 'contract')}>Сохранить</button>
                </div>
            </div>
        </div>
        <br />
        <div className={'container'}>
            <h3>Пользователи</h3>
            <table className={'table'}>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Имя</th>
                        <th>Фамилия</th>
                        <th>Администратор</th>
                    </tr>
                </thead>
                <tbody>
                {
                    organization.users.map(function(user) {
                        return <tr key={user['id']}>
                            <td>{user.email}</td>
                            <td>{user['first_name']}</td>
                            <td>{user['last_name']}</td>
                            <td>{user['is_admin'] ? 'Да' : 'Нет'}</td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    </div>;
}

export default OrganizationPage;