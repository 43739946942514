import React, { useEffect } from 'react';
import { PreloaderLink } from './../component/Preloader';
import { getReestr } from './../ajax/api-requests';
import './ReestrList.scss';

function ReestrList({ reestrs }) {
    useEffect(() => {
        document.title = 'Список реестров';
    }, []);

    if (!Array.isArray(reestrs)) {
        return <div>
            Заказанных реестров на данный момент нет.
        </div>;
    }

    return <div className={'container'}>
        <h3>Список реестров</h3>
        <table className={'table'}>
            <thead>
                <tr>
                    <th className={'v-all title-th'}>Название</th>
                    <th className={'v-big'}>Тип выписки</th>
                    <th className={'v-big'}>Заказчик</th>
                    <th className={'v-all'}>Выписки</th>
                    <th className={'v-all'}>Выполнено</th>
                    <th className={'v-big'}>Дата создания</th>
                </tr>
            </thead>
            <tbody>
            {
                reestrs.map((request) => {
                    return <tr key={request.id}>
                        <td className={'v-all'}><PreloaderLink url={'/reestr/' + request.id} preloaders={[{ callback: getReestr, arguments: [ request.id ], name: 'reestr' }]}>{request.title}</PreloaderLink></td>
                        <td className={'v-big'}>{request.type === 1 ? 'Простая' : 'Переход прав'}</td>
                        <td className={'v-big'}>{request.user.email}</td>
                        <td className={'v-all'}>{request.cnt_all}</td>
                        <td className={'v-all'}>{request.cnt_done}</td>
                        <td className={'v-big'}>{request.ctime}</td>
                    </tr>
                })
            }
            </tbody>
        </table>
    </div>
}

export default ReestrList;