import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import './css/main.scss';
import './App.scss';

import Header from './structure/Header';
import LeftBar from './structure/LeftBar';
import Content from './structure/Content';

import LocalStorage from './service/localStorage';
import LoginFormComponent from './component/LoginFormComponent';

const PrivateArea = function () {
    const [menu, setMenu] = useState({
        open: null,
        close: null
    });

    useEffect(() => {
        const leftBar = document.getElementById('left-bar');
        const overlay = document.getElementById('overlay');

        setMenu({
            open: () => {
                if (leftBar.style.display === 'block') {
                    leftBar.style.display = 'none';
                    overlay.style.display = "none";
                } else {
                    leftBar.style.display = 'block';
                    overlay.style.display = "block";
                }
            },
            close: () => {
                leftBar.style.display = "none";
                overlay.style.display = "none";
            }
        });
    }, []);

    return <>
        <Header menu={menu}/>
        <LeftBar menu={menu} />
        <Content />
    </>;
};

const PrivateRoute = function({user}) {
  return <Route path="/">
    {() => {
      return user.data.token
          ? <PrivateArea />
          : <Redirect
              to={{
                pathname: "/login"
              }}
          />
    }}
  </Route>
};

const App = function({ user }) {
  const authenticateUser = (data) => {
      LocalStorage.setItem('id', data.id);
      LocalStorage.setItem('email', data.email);
      LocalStorage.setItem('firstName', data.firstName);
      LocalStorage.setItem('lastName', data.lastName);
      LocalStorage.setItem('token', data.token);

      user.update({
        ...user.data,
        id: data.id,
        firstName: data.firstName,
        lastName: data.firstName,
        email: data.email,
        token: data.token,
      });
  };

  return <Router>
    <Switch>
      <Route exact path="/login">
        {
          user.data.id && user.data.token
            ? <Redirect to={{ pathname: "/" }} />
            : <LoginFormComponent authenticate={authenticateUser}/>
        }
      </Route>
      <PrivateRoute user={user} />
    </Switch>
  </Router>;
};

export default App;