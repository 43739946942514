import React from 'react';
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import './Content.scss';

import MakeReestrPage from "../page/MakeReestrPage";
import { PreloaderComponent } from "../component/Preloader";
import { getOrganization, getOrganizations, getReestr, getReestrList } from "../ajax/api-requests";
import ReestrList from "../page/ReestrList";
import ReestrPage from "../page/ReestrPage";
import OrganizationsListPage from "../page/OrganizationsListPage";
import OrganizationPage from "../page/OrganizationPage";

function Content() {
    return <div className='main'>
        <br />
        <Switch>
            <Route exact path="/make_reestr">
                <MakeReestrPage />
            </Route>
            <Route exact path="/reestr">
                <PreloaderComponent key={'reestr_list'} preloaders={[{callback: getReestrList, name: 'reestrs'}]}>
                    <ReestrList />
                </PreloaderComponent>
            </Route>
            <Route exact path="/">
                <Redirect
                    to={{
                        pathname: "/reestr"
                    }}
                />
            </Route>
            <Route exact path="/reestr/:id">
                <PreloaderComponent key={'reestr'} preloaders={[{callback: getReestr, name: 'reestr'}]}>
                    <ReestrPage />
                </PreloaderComponent>
            </Route>
            <Route exact path="/organization">
                <PreloaderComponent key={'org_list'} preloaders={[{callback: getOrganizations, name: 'organizations'}]}>
                    <OrganizationsListPage />
                </PreloaderComponent>
            </Route>
            <Route exact path="/organization/:id">
                <PreloaderComponent key={'org'} preloaders={[{callback: getOrganization, name: 'organization'}]}>
                    <OrganizationPage />
                </PreloaderComponent>
            </Route>
        </Switch>
    </div>
}

export default Content;